import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const SplitApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_HOST,
    prepareHeaders: (headers, { getState }) => {
      const token = localStorage.getItem("token");
      // If we have a token set in state, let's assume that we should be passing it.
      if (token) {
        headers.set("authorization", `Bearer ${token}`);
      }
      return headers;
    }
  }),

  tagTypes: [
    "BackTest",
    "BankAccounts",
    "BasketSettingService",
    "Client",
    "Commissions",
    "Mails",
    "Orders",
    "PamComments",
    "PamGroup",
    "RebalancingRequests",
    "RiskProfileSettingService",
    "SolutionSettingService",
    "StrategieSettingService",
    "Templates",
    "Tickers",
    "Permissions",
    "TickersAssetService",
    "UserRoles",
    "Users",
    "Dividend",
    "RoleDetails",
    "DividendRebalancing"
  ],
  endpoints: () => ({})
});
